
import { Component, Vue } from 'vue-property-decorator'
import ContactContent from './ContactContent.vue'
import CardWithTitle from '@/components/Card/CardWithTitle.vue';
import { i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  components: {
    ContactContent, 
    CardWithTitle
  }, 
  metaInfo: () => ({
    title: DynamicTranslationService.contactTitle
  })
})
export default class Contact extends Vue {

}
